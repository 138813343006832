<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard title="Diseñadores" subtitle="Listado de diseñadores asignados a la tienda"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                no-data-text="No hay diseñadores ingresados"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left" v-html="props.item.name"></td>
                    <td class="text-xs-right" v-html="`${props.item.ranking}`"></td>
                    <td class="text-xs-right">
                      {{ $moment.duration(props.item.time, 'seconds').minutes() }}:{{
                        $moment.duration(props.item.time, 'seconds').seconds()
                      }}
                      min
                    </td>
                    <td @click="changeStatus(props.item)">
                      <app-status :value="props.item.status"></app-status>
                    </td>
                    <td class="text-xs-right">
                      <v-icon
                        v-if="user.role.id === 1 || user.role.id === 2"
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                      >
                        far fa-edit
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogItem" max-width="500px">
      <v-card>
        <app-title-dialog :title="'Modificar diseñador'" :close-action="() => (dialogItem = false)" close>
        </app-title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field v-model="item.name" label="Nombre" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="item.user" label="Usuario" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="item.pass"
                label="Contraseña"
                type="password"
                hide-details
                hint="La contraseña ingresada reemplazara la anterior"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-switch v-model="item.status" label="Activo" hide-details> </v-switch>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialogItem = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveItem"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn fab dark fixed bottom right color="accent" @click="editItem({})">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { MI_STORE } from '../../config'
import titleCard from '../useful/titleCard.vue'
import appStatus from '../useful/status.vue'
import appTitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'StoreFlowers',
  components: { titleCard, appStatus, appTitleDialog },
  data: () => ({
    dialogItem: false,
    headers: [
      { text: 'Nombre', align: 'left', sortable: false },
      { text: 'Calificación', align: 'right', sortable: false },
      { text: 'Tiempo x producto', align: 'right', sortable: false },
      { text: 'Estado', align: 'center', sortable: false },
      { text: '', align: 'right', sortable: false }
    ],
    items: [],
    item: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {
        const res = await this.$http.get(`${MI_STORE}/designers`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveItem() {
      try {
        const res = await this.$http.post(`${MI_STORE}/designers`, {
          item: this.item
        })
        if (!res.data.error) {
          this.getItems()
          this.dialogItem = false
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    changeStatus(item) {
      this.item = item
      this.item.status = !this.item.status
      this.saveItem()
    },
    editItem(item) {
      this.item = item
      this.dialogItem = true
    }
  }
}
</script>

<style></style>
